import React from 'react'
import "./portfolio.css"
import pom from'../../assets/pom.png'
import brick from'../../assets/brick.png'
import sl from'../../assets/sl.png'
import connect6 from'../../assets/connect6.png'
import rock from'../../assets/rock.png'



const Portfolio = () => {
  return (
    <section id='portfolio'> 
    
    {/* <h5>My Recent Work</h5> */}
    <h2> Portfolio</h2>

    <div className="container portfolio__container">
      <article className='portfolio__item'>
        <div className="portfolio__item-image">
          <img src={connect6}/>

        </div>
        <h3>Connect 6</h3>
        <a href='https://github.com/dontknow462/Connect-6-Java-game' className='btn' target='_blank'>Github</a>

      </article>
      <article className='portfolio__item'>
        <div className="portfolio__item-image">
          <img src={sl}></img>

        </div>
        <h3>Snake and Ladder</h3>
        <a href='https://github.com/dontknow462/Snake-and-Ladder-JAVA' className='btn' target='_blank'>Github</a>

      </article>
      <article className='portfolio__item'>
        <div className="portfolio__item-image">
          <img src={brick}></img>

        </div>
        <h3>Brick Breaker</h3>
        <a href='https://github.com/dontknow462/JAVA-Brick-Breaker' className='btn' target='_blank'>Github</a>

      </article>
      <article className='portfolio__item'>
        <div className="portfolio__item-image">
          <img src={pom}></img>

        </div>
        <h3>Pomodoro Timer</h3>
        <a href='https://roshan-pomodoro-technique.netlify.app/' className='btn' target='_blank'>Github</a>

      </article>
      <article className='portfolio__item'>
        <div className="portfolio__item-image">
          <img src={rock}></img>

        </div>
        <h3>Rock, Paper and Scissors</h3>
        <a href='https://github.com/dontknow462/Rock-Paper-Scissor-game-' className='btn' target='_blank'>Github</a>
        <a href='https://rockpaperscissorr.netlify.app/ ' className='btn' target='_blank'>Live Demo</a>


      </article>
    
    </div>
    </section>
  )
}

export default Portfolio