import React from 'react'
import "./contact.css"
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import  { useRef } from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {
  const form =useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w006n62', 'template_j12lbv7', form.current, 'HseHbaXzFpjDPeOBX')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
 
 
  return (
    <section id='contact'>
      {/* <h5>
        Get in Touch
      </h5> */}
      <h2>
        Contact Me
      </h2>

      <div className="container contact__container">
          <div className="contact__options">

                <article className='contact__option'>
                  <MdOutlineEmail className='contact__option-icon'/>
                    <h4>Email</h4>
                    <h5>usernamerosan@gmail.com</h5>
                    <a href="mailto:rosan_wanted@yahoo.com" target="_blank">Send a message</a>
                </article>

                <article className='contact__option'>
                  <BsWhatsapp className='contact__option-icon' />
                    <h4>Whatsapp</h4>
                    <h5>07311860088</h5>
                    <a href="https://api.whatsapp.com/send?phone+447311860088" target="_blank">Send a message</a>
                </article>

               

         
          </div>


          <form ref={form}  onSubmit={sendEmail}>
            <input type='text' name='name' placeholder='Your Full Name' required />
            <input type='email' name='email' placeholder='Your Email' required />
            <textarea name='message' rows='7' placeholder='Your Message'/>
            <button type='submit' className='btn btn-primary'>Send Message</button>
          
          </form>
      </div>

    </section>
  )
}

export default Contact