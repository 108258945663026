import React from 'react'
import {BsLinkedin} from "react-icons/bs"
import {FaGithub} from "react-icons/fa";
import {FiDribbble} from "react-icons/fi"

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/roshan-bhusal-a517621b4/'><BsLinkedin/></a>
        <a href='https://github.com/dontknow462'><FaGithub/></a>
        {/* <a href='www.youtube.com'><FiDribbble/></a> */}
    </div>
  )
}

export default HeaderSocials