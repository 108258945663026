import React from 'react'
import "./about.css"
import ME from '../../assets/foto3.JPG'
import { FaUniversity } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      {/* <h5>Get to Know</h5> */}
      <h2>About me</h2>

      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image " />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaUniversity className='about__icon' />
              <h2> Education</h2>
              <h5>BSc (Hons) Computer Science</h5>
              <small>Brunel University, Uxbridge, Middlesex (Sept 2021 – June 2024)</small>

            </article>
            {/* <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5> Clients</h5>
              <small>300+ Worldwide</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5> projects</h5>
              <small>80+ projects</small>
            </article> */}
          </div>

          <p>
            Hello! My name is Roshan and I am a 2nd year Computer Science student. My interest in programming started around 2016 with HTML/CSS and Javascript as a hobby. Fast-forward to today, I have been learning new technologies and bulding user friendly products.
          </p>

          <a href='#contact' className='btn btn-primary'>Lets Talk</a>
        </div>
      </div>
    </section>

  )
}

export default About;