import React from 'react'
import "./experience.css"
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      {/* <h5>What skills I have</h5> */}
      <h2>My Skills </h2>

      <div className="container experience__container">
      <div className="experience__frontend">
        <h3>Skills</h3>
        <div className="experience__content">
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> HTML/CSS</h4>
         
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> JavaScript</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Reactjs</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Java</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> MySql</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Git</h4>
          </article>
         
        </div>
      </div>

      <div className="experience__backend">

      <h3>Modules studied in University</h3>
        <div className="experience__content">
        <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Introductory Programming</h4>
          </article>
        <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Information Systems and Organisations</h4>
         
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4>Data and Information </h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Algorithms and their
Applications</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Software Development and Management</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Usability Engineering</h4>
          </article>
          <article className='experience__details'>
            <BsPatchCheckFill/>
            <h4> Networks and Operating Systems</h4>
          </article>
          
        </div>



      </div>
      </div>
    </section>
  )
}

export default Experience